<template>
  <div class="mt-2">
    <div class="mb-2">
      <div
        class="image-viewer position-relative border rounded overflow-hidden"
      >
        <div v-if="image" class="button-container">
          <button type="button" class="btn btn-danger" @click="changeImage">
            Change Image
          </button>
        </div>
        <img v-if="image" :src="image" class="cover mr-1" />
      </div>
    </div>

    <form method="POST" enctype="multipart/form-data">
      <image-selector
        ref="fileImageSelector"
        name="image"
        class="mr-1"
        @loaded="onLoad"
      />
    </form>
  </div>
</template>

<script>
import ImageSelector from "./ImageSelector.vue";
import jsonAPI from "@/api";

export default {
  name: "ImageUploader",
  components: {
    ImageSelector,
  },
  props: {
    url: {
      type: String,
      default: "",
    },
    imagePath: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      image: "",
      type: "",
    };
  },
  watch: {
    imagePath(date) {
      this.image = date;
    },
  },
  methods: {
    onLoad(image) {
      this.image = image.src;
      this.type = image.type;
      this.persist(image.file);
    },
    persist(image) {
      let data = new FormData();

      data.append("image", image);
      if(this.type === "image/svg+xml") {
        data.append("type", "image/svg");
      }else {
        data.append("type", this.type);
      }

      jsonAPI
        .post(this.url, data)
        .then((response) => {
          window.Bus.$emit("flash-message", {
            text: "Logo uploaded",
            type: "success",
          });
          this.$emit("image-changed", response.data.path);
        })
        .catch((error) => {
          if (error.response.status == 422) {
            window.Bus.$emit("flash-message", {
              text: error.response.data.errors.image[0],
              type: "error",
            });
          } else {
            window.Bus.$emit("flash-message", {
              text: "Could not upload logo",
              type: "error",
            });
          }
        });
    },
    remove() {
      if (!this.image) return;
      jsonAPI.delete(this.url).then(() => {
        window.Bus.$emit("flash-message", {
          text: "Logo removed",
          type: "success",
        });
        this.image = null;
        this.$emit("image-changed", "");
      });
    },
    // call function from image-selector component
    changeImage() {
      this.$refs.fileImageSelector.changeImage();
    },
  },
};
</script>

<style scoped>
.cover {
  object-fit: cover;
  width: 100%;
  height: 150px;
}

.image-viewer {
  width: 100%;
  height: 150px;
  cursor: pointer;
  padding: 10px;
}

.image-viewer .button-container {
  visibility: hidden;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  background-color: rgba(0, 0, 0, 0.4);
}
.image-viewer img {
  height: 100%;
  width: auto;
}

.image-viewer:hover .button-container {
  visibility: visible;
}

.btn-danger {
  background: yellow;
  color: #1a1a1a;
}
</style>
