<template>
  <div
    class="color border"
  >
    <div class="check_wrapper has-children-centered">
      <div class="check_circle has-children-centered">
        <svg
          version="1.1"
          role="presentation"
          width="12"
          height="12"
          viewBox="0 0 1792 1792"
          class="check"
        ><path
          d="M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z"
          class="check_circle_path"
        /></svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ColorSelect"
}
</script>


<style type="text/css">
.color {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-right: 10px;
    border-radius: 6px;
    cursor: pointer;
}
.color:last-child {
    margin-right: 0;
}
.color:hover, .color.active_color {
    opacity: .9;
    box-shadow: inset 0 0 2px rgba(0,0,0,.75);
    outline: none;
}
.color .check_wrapper {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
}
.color.active_color .check_wrapper {
    opacity: 1;
}
.color .has-children-centered {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.color .check_circle {
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background-color: rgba(0,0,0,.15);
}
.color .check_circle_path {
    fill: #fff;
}
</style>