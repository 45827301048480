<template>
  <div class="custom-file">
    <input
      id="customFile"
      ref="imageSelector"
      type="file"
      accept="image/*"
      class="custom-file-input"
      @change="onChange"
    />
    <label class="custom-file-label" for="customFile">Choose file</label>
  </div>
</template>

<script>
export default {
  methods: {
    onChange(e) {
      if (!e.target.files.length) return;

      let file = e.target.files[0];
      let type = file.type;
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = (e) => {
        let src = e.target.result;
        this.$emit("loaded", { src, type, file });
      };
    },
    // initiate input click
    changeImage() {
      this.$refs.imageSelector.click();
    },
  },
};
</script>
