var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.layout,{tag:"component"},[_c('prompt',{attrs:{"active":_vm.isModalVisible},on:{"close":function($event){_vm.isModalVisible = false}}},[_c('h3',{attrs:{"slot":"header"},slot:"header"},[_vm._v(" Send an invitation ")]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('invite-form',{on:{"close":function($event){_vm.isModalVisible = false},"inviteCreated":_vm.getInvites}})],1)]),_c('prompt',{attrs:{"active":_vm.isUserModalVisible},on:{"close":function($event){_vm.isUserModalVisible = false}}},[_c('h3',{attrs:{"slot":"header"},slot:"header"},[_vm._v(" Update team member ")]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('user-form',{attrs:{"user":_vm.user}})],1)]),_c('div',{staticClass:"row fadeInUp",attrs:{"role":"main"}},[_c('div',{staticClass:"col-12 col-md-3"},[_c('OrganizationNav')],1),_c('div',{staticClass:"col-12 col-md-9"},[_c('div',{staticClass:"card border-0 shadow-sm mb-6"},[_c('div',{staticClass:"card-header"},[_c('h4',[_vm._v("Organization settings")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-6 required",class:{
                'is-invalid': _vm.errors.has('title'),
              }},[_c('label',{staticClass:"control-label text-capitalize",attrs:{"for":"name"}},[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.organization.title),expression:"organization.title"},{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],staticClass:"form-control",attrs:{"id":"name","type":"text","name":"name"},domProps:{"value":(_vm.organization.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.organization, "title", $event.target.value)}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('title')),expression:"errors.has('title')"}],staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.errors.first("title")))])]),_c('div',{staticClass:"form-group col-md-6 required",class:{
                'is-invalid': _vm.errors.has('domain'),
              }},[_c('label',{staticClass:"control-label text-capitalize",attrs:{"for":"domain"}},[_vm._v("Shop domain")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.organization.domain),expression:"organization.domain"},{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],staticClass:"form-control",attrs:{"id":"domain","type":"text","name":"domain"},domProps:{"value":(_vm.organization.domain)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.organization, "domain", $event.target.value)}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('domain')),expression:"errors.has('domain')"}],staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.errors.first("domain")))])])]),_c('div',{staticClass:"rounded py-3 px-5 mt-2 mb-4 border"},[_c('editable-address',{attrs:{"simple":true,"address":_vm.organization.billingAddress,"default-country":_vm.getDefaultCountry}})],1),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-12 required",class:{
                'is-invalid': _vm.errors.has('signature'),
              }},[_c('label',{staticClass:"control-label text-capitalize",attrs:{"for":"signature"}},[_vm._v("Email signature")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.organization.signature),expression:"organization.signature"},{name:"validate",rawName:"v-validate",value:({ required: false }),expression:"{ required: false }"}],staticClass:"form-control",attrs:{"id":"signature","name":"signature"},domProps:{"value":(_vm.organization.signature)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.organization, "signature", $event.target.value)}}}),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('signature')),expression:"errors.has('signature')"}],staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.errors.first("signature")))])])]),_c('div',{staticClass:"form-row mt-6"},[_c('div',{staticClass:"form-group col-md-12"},[_c('h4',[_vm._v("Styling")]),_c('p',{staticClass:"mb-0"},[_vm._v("Here you can set the styles of your customer facing applications, like our forms")])]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',{staticClass:"control-label",attrs:{"for":"feature_color"}},[_vm._v("Background color:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bgColor),expression:"bgColor"}],staticClass:"form-control",attrs:{"id":"feature_color","type":"text","name":"feature_color"},domProps:{"value":(_vm.bgColor)},on:{"input":function($event){if($event.target.composing){ return; }_vm.bgColor=$event.target.value}}}),_c('div',{staticClass:"d-block mt-3"},_vm._l((_vm.colors),function(color){return _c('ColorSelect',{key:color,class:[
                    {
                      active_color: color === _vm.bgColor,
                    } ],style:({ background: color }),nativeOn:{"click":function($event){return _vm.chooseColor('bgColor', color)}}})}),1)]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',{staticClass:"control-label",attrs:{"for":"feature_color"}},[_vm._v("Header color:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.headerColor),expression:"headerColor"}],staticClass:"form-control",attrs:{"id":"feature_color","type":"text","name":"feature_color"},domProps:{"value":(_vm.headerColor)},on:{"input":function($event){if($event.target.composing){ return; }_vm.headerColor=$event.target.value}}}),_c('div',{staticClass:"d-block mt-3"},_vm._l((_vm.colors),function(color){return _c('ColorSelect',{key:color,class:[
                    {
                      active_color: color === _vm.headerColor,
                    } ],style:({ background: color }),nativeOn:{"click":function($event){return _vm.chooseColor('headerColor', color)}}})}),1)])]),_c('div',{staticClass:"form-row mt-6"},[_c('div',{staticClass:"form-group col-md-12"},[_c('h4',[_vm._v("Organization Logo")]),_c('p',{staticClass:"mb-0"},[_vm._v("Upload Organization logo here")])]),_c('div',{staticClass:"form-group col-md-6"},[_c('image-uploader',{attrs:{"id":"logo","url":"organization/mail/logo","image-path":_vm.organization.settings.smtp.logo},on:{"image-changed":_vm.uploadLogoSuccess}})],1)])]),_c('div',{staticClass:"card-footer"},[_c('ButtonLoading',{class:'btn btn-secondary float-right',attrs:{"loading":_vm.loading,"title":'Save settings'},nativeOn:{"click":function($event){return _vm.submitForm.apply(null, arguments)}}})],1)]),_c('div',{staticClass:"card border-0 shadow-sm mb-6"},[_c('div',{staticClass:"card-header border-bottom"},[_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"c-status c-status-prepend",class:_vm.isActive ? 'c-status-success' : 'c-status-pending',attrs:{"role":"status"}}),_vm._v(" Account status ")])]),_c('div',{staticClass:"card-body"},[_c('p',[_vm._v(_vm._s(_vm.statusText))]),_c('button',{staticClass:"btn float-right",class:_vm.buttonColor,attrs:{"type":"submit"},on:{"click":_vm.changeStatus}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")])])]),_c('div',{staticClass:"card border-0 shadow-sm mb-6"},[_c('div',{staticClass:"card-header border-bottom"},[_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"c-status c-status-prepend",class:_vm.hasSubscription ? 'c-status-success' : 'c-status-pending',attrs:{"role":"status"}}),_vm._v(" Subscription "),_c('router-link',{staticClass:"float-right",attrs:{"to":{
                name: 'organization.subscription.history',
              }}},[_vm._v(" Payment history ")])],1)]),_c('div',{staticClass:"card-body"},[(_vm.hasSubscription)?_c('div',[_c('div',{staticClass:"border rounded p-2"},[_c('strong',[_vm._v(_vm._s(_vm.activePlan.name)+" "),_c('span',{staticClass:"text-muted ml-3"},[_vm._v("$"+_vm._s(_vm.activePlan.amount)+" / m")])]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.activePlan.text)+" ")])])]):_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"text-muted mb-0"},[_vm._v(" You don't have an active subscription ")])])]),_c('div',{staticClass:"card-footer bg-white"},[(!_vm.hasSubscription)?_c('router-link',{staticClass:"btn btn-outline-secondary btn-block",attrs:{"to":{
              name: 'organization.subscription.create',
            }}},[_vm._v(" Select plan ")]):_c('router-link',{staticClass:"btn btn-outline-secondary btn-block",attrs:{"to":{ name: 'organization.subscription' }}},[_vm._v(" Update subscription ")])],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }