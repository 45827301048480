<template>
  <component :is="layout">
    <prompt :active="isModalVisible" @close="isModalVisible = false">
      <h3 slot="header">
        Send an invitation
      </h3>
      <div slot="content">
        <invite-form
          @close="isModalVisible = false"
          @inviteCreated="getInvites"
        />
      </div>
    </prompt>

    <prompt :active="isUserModalVisible" @close="isUserModalVisible = false">
      <h3 slot="header">
        Update team member
      </h3>
      <div slot="content">
        <user-form :user="user" />
      </div>
    </prompt>

    <div class="row fadeInUp" role="main">
      <div class="col-12 col-md-3">
        <OrganizationNav />
      </div>
      <div class="col-12 col-md-9">
        <div class="card border-0 shadow-sm mb-6">
          <div class="card-header">
            <h4>Organization settings</h4>
          </div>
          <div class="card-body">
            <div class="form-row">
              <div
                class="form-group col-md-6 required"
                :class="{
                  'is-invalid': errors.has('title'),
                }"
              >
                <label for="name" class="control-label text-capitalize">Name</label>
                <input
                  id="name"
                  v-model="organization.title"
                  v-validate="{ required: true }"
                  type="text"
                  name="name"
                  class="form-control"
                />
                <span v-show="errors.has('title')" class="invalid-feedback">{{
                  errors.first("title")
                }}</span>
              </div>
              <div
                class="form-group col-md-6 required"
                :class="{
                  'is-invalid': errors.has('domain'),
                }"
              >
                <label for="domain" class="control-label text-capitalize">Shop domain</label>
                <input
                  id="domain"
                  v-model="organization.domain"
                  v-validate="{ required: true }"
                  type="text"
                  name="domain"
                  class="form-control"
                />
                <span v-show="errors.has('domain')" class="invalid-feedback">{{
                  errors.first("domain")
                }}</span>
              </div>
            </div>
            <div class="rounded py-3 px-5 mt-2 mb-4 border">
              <editable-address
                :simple="true"
                :address="organization.billingAddress"
                :default-country="getDefaultCountry"
              />
            </div>
            <div class="form-row">
              <div
                class="form-group col-md-12 required"
                :class="{
                  'is-invalid': errors.has('signature'),
                }"
              >
                <label for="signature" class="control-label text-capitalize">Email signature</label>
                <textarea
                  id="signature"
                  v-model="organization.signature"
                  v-validate="{ required: false }"
                  name="signature"
                  class="form-control"
                />
                <span v-show="errors.has('signature')" class="invalid-feedback">{{
                  errors.first("signature")
                }}</span>
              </div>
            </div>
            <div class="form-row mt-6">
              <div class="form-group col-md-12">
                <h4>Styling</h4>
                <p class="mb-0">Here you can set the styles of your customer facing applications, like our forms</p>
              </div>
              <div class="form-group col-md-6">
                <label for="feature_color" class="control-label">Background color:</label>
                <input
                  id="feature_color"
                  v-model="bgColor"
                  type="text"
                  name="feature_color"
                  class="form-control"
                />
                <div class="d-block mt-3">
                  <ColorSelect
                    v-for="color in colors"
                    :key="color"
                    :style="{ background: color }"
                    :class="[
                      {
                        active_color: color === bgColor,
                      },
                    ]"
                    @click.native="chooseColor('bgColor', color)"
                  />
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="feature_color" class="control-label">Header color:</label>
                <input
                  id="feature_color"
                  v-model="headerColor"
                  type="text"
                  name="feature_color"
                  class="form-control"
                />
                <div class="d-block mt-3">
                  <ColorSelect
                    v-for="color in colors"
                    :key="color"
                    :style="{ background: color }"
                    :class="[
                      {
                        active_color: color === headerColor,
                      },
                    ]"
                    @click.native="chooseColor('headerColor', color)"
                  />
                </div>
              </div>
            </div>
            <div class="form-row mt-6">
              <div class="form-group col-md-12">
                <h4>Organization Logo</h4>
                <p class="mb-0">Upload Organization logo here</p>
              </div>
              <div class="form-group col-md-6">
                <image-uploader
                  id="logo"
                  url="organization/mail/logo"
                  :image-path="organization.settings.smtp.logo"
                  @image-changed="uploadLogoSuccess"
                />
              </div>
            </div>
          </div>
          <div class="card-footer">
            <ButtonLoading
              :loading="loading"
              :title="'Save settings'"
              :class="'btn btn-secondary float-right'"
              v-on:click.native="submitForm"
            />
          </div>
        </div>
        <div class="card border-0 shadow-sm mb-6">
          <div class="card-header border-bottom">
            <p class="mb-0">
              <span
                role="status"
                class="c-status c-status-prepend"
                :class="isActive ? 'c-status-success' : 'c-status-pending'"
              />
              Account status
            </p>
          </div>
          <div class="card-body">
            <p>{{ statusText }}</p>
            <button
              type="submit"
              class="btn float-right"
              :class="buttonColor"
              @click="changeStatus"
            >
              {{ buttonText }}
            </button>
          </div>
        </div>
        <div class="card border-0 shadow-sm mb-6">
          <div class="card-header border-bottom">
            <p class="mb-0">
              <span
                role="status"
                class="c-status c-status-prepend"
                :class="
                  hasSubscription ? 'c-status-success' : 'c-status-pending'
                "
              />
              Subscription
              <router-link
                :to="{
                  name: 'organization.subscription.history',
                }"
                class="float-right"
              >
                Payment history
              </router-link>
            </p>
          </div>
          <div class="card-body">
            <div v-if="hasSubscription">
              <div class="border rounded p-2">
                <strong
                  >{{ activePlan.name }}
                  <span class="text-muted ml-3"
                    >${{ activePlan.amount }} / m</span
                  ></strong
                >
                <p class="mb-0">
                  {{ activePlan.text }}
                </p>
              </div>
            </div>
            <div v-else class="text-center">
              <p class="text-muted mb-0">
                You don't have an active subscription
              </p>
            </div>
          </div>
          <div class="card-footer bg-white">
            <router-link
              v-if="!hasSubscription"
              class="btn btn-outline-secondary btn-block"
              :to="{
                name: 'organization.subscription.create',
              }"
            >
              Select plan
            </router-link>
            <router-link
              v-else
              class="btn btn-outline-secondary btn-block"
              :to="{ name: 'organization.subscription' }"
            >
              Update subscription
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import { mapGetters } from "vuex";
import { find, isEmpty } from "lodash";
import jsonAPI from "@/api";
import Default from "@/layouts/default.vue";
import Prompt from "@/components/UI/Prompt.vue";
import InviteForm from "@/components/Form/InviteForm.vue";
import UserForm from "@/components/Form/UserForm.vue";
import EditableAddress from "@/components/UI/EditableAddress.vue";
import OrganizationNav from "@/components/Nav/OrganizationNav.vue";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";
import ColorSelect from "@/components/UI/ColorSelect.vue";
import ImageUploader from "@/components/UI/ImageUploader.vue";

export default {
  name: "Organization",
  components: {
    Default,
    Prompt,
    EditableAddress,
    InviteForm,
    UserForm,
    OrganizationNav,
    ButtonLoading,
    ColorSelect,
    ImageUploader,
  },
  beforeRouteEnter(to, from, next) {
    jsonAPI.get("organization/current").then((response) => {
      next((vm) => {
        vm.organization = response.data;
        if (!vm.organization.billingAddress) {
          vm.organization.billingAddress = {};
        }
        vm.users = vm.organization.users;
        if (vm.organization.settings) {
          vm.bgColor = vm.organization.settings.bgColor ?? '';
          vm.headerColor = vm.organization.settings.headerColor ?? '';
        }
      });
    });
  },
  data: function() {
    return {
      layout: "Default",
      isModalVisible: false,
      isUserModalVisible: false,
      loading: false,
      organization: {
        subscription: {},
        plans: [],
        billingAddress: {},
        settings: {
          smtp: {
            logo: ""
          }
        }
      },
      users: [],
      invites: {
        data: [],
      },
      user: {},
      colors: [
        "",
        "#1fbc9c",
        "#2ecc70",
        "#3398db",
        "#a463bf",
        "#3d556e",
        "#f2c511",
        "#e84b3c",
      ],
      bgColor: '',
      headerColor: '',
    };
  },
  computed: {
    ...mapGetters(["isActive"]),
    hasSubscription() {
      return !isEmpty(this.organization.subscription);
    },
    activePlan() {
      if (this.hasSubscription && this.organization.plans.length) {
        return find(this.organization.plans, {
          gateway_id: this.organization.subscription.stripe_plan,
        });
      }
      return null;
    },
    buttonColor() {
      return this.isActive ? "btn-danger" : "btn-success";
    },
    buttonText() {
      return this.isActive ? "Deactivate account" : "Activate account";
    },
    statusText() {
      if (this.isActive) {
        return "Your account is active and running.";
      }
      return "Your account is currently deactivated, which means email and sms messages are not being sent out.";
    },
    getDefaultCountry() {
      return (
        this.organization.country_obj ?? {
          name: "",
          code: "",
        }
      );
    },
  },
  mounted() {
    this.getInvites();
  },
  methods: {
    submitForm: function() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          const org = this.organization;
          org.billingAddress.organization_id = this.organization.id;
          jsonAPI
            .put("/organization/" + this.organization.id, org)
            .then(() => {
              window.Bus.$emit("flash-message", {
                text: "organization updated",
                type: "success",
              });
              this.loading = false;
            })
            .catch((error) => {
              Object.keys(error.response.data.errors).forEach((key) => {
                this.$validator.errors.add({
                  field: key,
                  msg: error.response.data.errors[key][0],
                });
              });
              this.loading = false;
              window.Bus.$emit("flash-message", {
                text: "error main",
                type: "error",
              });
            });
        } else {
          this.loading = false;
        }
      });
    },
    editUser(userId) {
      this.user = this.users.filter((item) => {
        return (item.id = userId);
      })[0];
      this.isUserModalVisible = true;
    },
    changeStatus() {
      const org = this.organization;
      if (!org.settings) {
        org.settings = {};
      }
      org.settings.active = org.settings ? !org.settings.active : true;
      delete org.billingAddress;
      jsonAPI
        .put("/organization/" + this.organization.id, org)
        .then((response) => {
          this.$store.dispatch("SET_ORGANIZATION", response.data);
          window.Bus.$emit("flash-message", {
            text: "organization updated",
            type: "success",
          });
          this.loading = false;
        })
        .catch((error) => {
          Object.keys(error.response.data.errors).forEach((key) => {
            this.$validator.errors.add({
              field: key,
              msg: error.response.data.errors[key][0],
            });
          });
          this.loading = false;
          window.Bus.$emit("flash-message", {
            text: "error main",
            type: "error",
          });
        });
    },
    getInvites() {
      jsonAPI.get("/invite").then((response) => {
        this.invites = response.data;
      });
    },
    deleteInvite(_id) {
      jsonAPI
        .delete("/invite/" + _id)
        .then(() => {
          this.invites.data = this.invites.data.filter((obj) => {
            return obj.id !== _id;
          });
        })
        .catch(() => {
          window.Bus.$emit("flash-message", {
            text: "An error occurred while deleting the invite.",
            type: "error",
          });
        });
    },
    chooseColor(type, color) {
      this[type] = color;
      this.organization.settings[type] = color;
    },
    uploadLogoSuccess(path) {
      this.organization.settings.smtp.logo = path;
    }
  },
};
</script>
